<template>
  <VCard color="primaryCard" flat v-bind="$attrs" tile>
    <VRow class="fill-height" align="center">
      <VCol cols="4">
        <slot name="clock">
          <KioskClockI10n
            :tag="'VRow'"
            :value="clock.value"
            :locale="$i18n.locale"
            :locales="currentPrefTimeFormat"
          >
            <template #default="{ time: [title, hours, daytime] }">
              <VCol cols="12" class="text-right">
                <VRow align="end" justify="end">
                  <h3 class="display-3 font-weight-regular" v-text="hours" />
                  <span class="subtitle-1 text-lowercase" v-text="daytime" />
                </VRow>
                <VDivider class="my-4" />
                <label class="title d-block text-capitalize" v-text="title" />
              </VCol>
            </template>
          </KioskClockI10n>
        </slot>
      </VCol>
      <VCol cols="8"> <slot /> </VCol>
    </VRow>
  </VCard>
</template>

<script>
import useClock from '@/lib/kiosk/utils/use-clock'
import KioskClockI10n from '@/components/KioskClockI10n'
export default {
  name: 'KioskDialLoginCard',
  components: {
    KioskClockI10n
  },
  inheritAttrs: false,
  props: {
    prefTimeFormat: {
      type: Object,
      default() {
        return {
          'H:i': ['ddd, D MMM', 'H:mm'],
          'H:i T': ['ddd, D MMM', 'H:mm'],
          'h:ia': ['ddd, D MMM', 'h:mm', 'A'],
          'h:ia T': ['ddd, D MMM', 'h:mm', 'A']
        }
      }
    },
    localePrefTimeFormat: {
      type: Object,
      default() {
        return {
          en: ['ddd, D MMM', 'hh:mm', 'A'],
          fr: ['ddd, D MMM', 'HH:mm'],
          es: ['ddd, D MMM', 'HH:mm']
        }
      }
    }
  },
  data() {
    const clock = useClock()

    return {
      clock
    }
  },
  computed: {
    currentPrefTimeFormat() {
      const setting = this.$store.getters['kiosk/setting']
      const pref = setting.pref || {}

      if (!this.prefTimeFormat[pref.timeFormat]) {
        return this.localePrefTimeFormat
      }

      return { [this.$i18n.locale]: this.prefTimeFormat[pref.timeFormat] }
    }
  },
  beforeDestroy() {
    this.clock.destroy()
  }
}
</script>
