<template>
  <VRow class="fill-height" align="stretch">
    <VCol cols="12">
      <KioskDialLoginCard class="cardPrimary fill-height">
        <KioskDialCredentialForm @submitted="onDialLoginFormSubmitted" />
      </KioskDialLoginCard>
    </VCol>
  </VRow>
</template>

<script>
import KioskDialCredentialForm from './components/KioskDialCredentialsForm'
import KioskDialLoginCard from './components/KioskDialLoginCard'

export default {
  name: 'DialCredentials',
  components: {
    KioskDialLoginCard,
    KioskDialCredentialForm
  },
  methods: {
    onDialLoginFormSubmitted(submitOk) {
      if (submitOk) {
        this.$router.push({ name: 'shift-control.index' })
      }
    }
  }
}
</script>
