<template>
  <VContainer>
    <VRow>
      <VCol cols="12">
        <KioskForm v-if="form" v-model="form" name="dialGuard">
          <template #submitted="submittedProps">
            <slot name="submitted" v-bind="submittedProps" />
          </template>
          <template #default>
            <VContainer class="ml-5 error-container">
              <VRow>
                <VCol cols="10" mb-4 class="error-wrapper">
                  <KioskDialFormNotifications :form="form" />
                </VCol>
                <VCol cols="10">
                  <KioskFormField
                    v-model="form.fields.username"
                    solo
                    autocomplete="off"
                    autocorrect="off"
                    autofill="off"
                  />
                  <KioskFormField
                    v-model="form.fields.password"
                    type="password"
                    solo
                    autocomplete="off"
                    autocorrect="off"
                    autofill="off"
                  />
                  <VBtn
                    large
                    data-heap-id="field_submit"
                    type="submit"
                    color="primary"
                    block
                    :loading="form.state.loading"
                    :disabled="form.$v.$invalid"
                  >
                    <span v-text="form.$t('login.btn.text')" />
                  </VBtn>
                </VCol>
              </VRow>
            </VContainer>
          </template>
        </KioskForm>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
import { required } from '@/validators'
import { FormBuilder } from '@/lib/kiosk'
import KioskForm from '@/components/KioskForm'
import KioskFormField from '@/components/KioskFormField'
import KioskDialFormNotifications from './KioskDialFormNotifications'

export default {
  name: 'KioskDialCredentialsForm',
  components: {
    KioskForm,
    KioskFormField,
    KioskDialFormNotifications
  },
  props: {
    formData: {
      type: Function,
      default() {
        return {
          username: null,
          password: null
        }
      }
    },
    noError: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: null,
      fields: this.formData()
    }
  },
  validations() {
    return {
      fields: {
        username: {
          required
        },
        password: {
          required
        }
      }
    }
  },
  mounted() {
    this.form = FormBuilder.fromComponent(this)
      .setFields((as) => ({
        username: as.component('VTextField'),
        password: as.component('VTextField')
      }))
      .setSubmitHandler((formState) => this.verify(formState))
      .build('dial')
  },
  methods: {
    async verify(payload) {
      await this.$store.dispatch('kiosk/verifyEmployeeCredentials', payload)
    }
  }
}
</script>
